export const getVideoStream = async (resolution: number, facingMode: string) => {
    console.log("videoservice", "getting video")
    type constraintsObject = {
        video: {
            frameRate?: { ideal: number },
            width?: { ideal: number },
            facingMode?: string,
            echoCancellation?: boolean,
            autoGainControl?: boolean,
            noiseSuppression?: boolean
        } | boolean
    }
    let constraints: constraintsObject = {
        video: {
            frameRate: { ideal: 15 },
            width: { ideal: resolution },
            facingMode: facingMode,
            echoCancellation: false,
            autoGainControl: false,
            noiseSuppression: false
        }
    }
    try {
        const stream = await window.navigator.mediaDevices.getUserMedia(constraints);
        return stream
    } catch (error) {
        console.log("error is: " + error.message)
        constraints = {
            video: {
                frameRate: { ideal: 15 },
                facingMode: facingMode
            }
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            return stream
        } catch (error) {
            console.log("error is: " + error.message)
            constraints = {
                video: {
                    facingMode: facingMode
                }
            }
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                return stream
            } catch (error) {
                console.log("error is: " + error.message)
                constraints = {
                    video: true
                }
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                return stream
            }
        }
    }
}

export const getAudioStream = async () => {
    const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });
    return stream
}

export const stopStream = (stream: MediaStream) => {
    if (stream) {
        stream.getTracks().forEach(track => {
            track.stop()
        })
    }
}