import config from 'config/config'
import { setControl } from '../store/slices/webrtcSlice'

let keyUp = false
let keyDown = false
let keyLeft = false
let keyRight = false


const keyDownFunc = (e: KeyboardEvent, remoteUID: string): void => {
    if (e.key === "ArrowUp") keyUp = true
    else if (e.key === "ArrowDown") keyDown = true
    else if (e.key === "ArrowLeft") keyLeft = true
    else if (e.key === "ArrowRight") keyRight = true
    let control = { x: 0.0, y: 0.0 }
    if (keyUp) control.y += 1.0
    if (keyDown) control.y -= 1.0
    if (keyLeft) control.x -= 1.0
    if (keyRight) control.x += 1.0
    setControl(control, remoteUID)
}

const keyUpFunc = (e: KeyboardEvent, remoteUID: string): void => {
    if (e.key === "ArrowUp") keyUp = false
    else if (e.key === "ArrowDown") keyDown = false
    else if (e.key === "ArrowLeft") keyLeft = false
    else if (e.key === "ArrowRight") keyRight = false
    let control = { x: 0.0, y: 0.0 }
    if (keyUp) control.y += 1.0
    if (keyDown) control.y -= 1.0
    if (keyLeft) control.x -= 1.0
    if (keyRight) control.x += 1.0
    setControl(control, remoteUID)
}

export const startListening = (remoteUID: string) => {
    if (config.isWeb) {
        document.addEventListener('keydown', e => { keyDownFunc(e, remoteUID) })
        document.addEventListener('keyup', e => { keyUpFunc(e, remoteUID) })
    }

}

export const stopListening = (remoteUID: string) => {
    if (config.isWeb) {
        document.removeEventListener('keydown', e => { keyDownFunc(e, remoteUID) })
        document.removeEventListener('keyup', e => { keyUpFunc(e, remoteUID) })
        keyUp = false
        keyDown = false
        keyLeft = false
        keyRight = false
    }
}