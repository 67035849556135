import React from 'react'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { ErrorBoundary } from 'react-error-boundary'
import { logError } from 'services/newFirebase'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    errorContainer: {
        height: "30vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    button: {
        backgroundColor: "#fbad18",
        borderRadius: 5,
        marginTop: 10,
        color: "white",
        '&:hover': {
            boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
            backgroundColor: "#fbad18",
            color: "white"
        }
    }
}))

type FallbackProps = {
    error?: Error
    resetErrorBoundary: () => void
}

const ErrorFallback = ({ resetErrorBoundary, error }: FallbackProps) => {
    const classes = useStyles()
    return (
        <div className={classes.errorContainer}>
            <Typography variant="h5">Something went wrong</Typography>
            <Button className={classes.button} onClick={resetErrorBoundary} variant="outlined">Try again</Button>
            <Typography variant="body2">{"name: " + error?.name}</Typography>
            <Typography variant="body2">{"message: " + error?.message}</Typography>
        </div>
    )
}


const MyErrorBoundary: React.FC = ({ children }) => {
    const ErrorHandler = (error: Error, info: { componentStack: string }): void => {
        logError(error?.message, info.componentStack)
    }
    return (
        <ErrorBoundary onError={ErrorHandler} FallbackComponent={ErrorFallback}>
            {children}
        </ErrorBoundary>
    )
}

export default MyErrorBoundary