import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';
import { Provider } from 'react-redux'

import store from './store/store'

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core'

const setSpacing = (factor: number) => {
  const width = window.innerWidth;
  if (width > 600) {
    return 8 * factor;
  } else {
    return 6 * factor;
  }
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ffdf53',
      main: '#fbad18',
      dark: '#c37e00',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#81eced',
      main: '#4ab9bb',
      dark: '#00898b',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: ["Helvetica", "Arial", "sans-serif"].join(','),
    h1: {
      fontSize: "4rem" // 64px
    },
    h5: {
      fontSize: "1.6rem" //25.6px
    },
    button: {
      fontSize: "1.0rem",
      fontFamily: ["Arial", "sans-serif"].join(',')
    },
    body1: {
      fontSize: "1.1rem" //17.5px
    },
    body2: {
      fontSize: "0.9rem" // 14.4px
    },
  },
  spacing: setSpacing(1)
});
theme = responsiveFontSizes(theme, { factor: 2, variants: ['body1', 'body2', 'h1', 'h2', 'h5', 'h6', 'button'] });

ReactDOM.render(
  //render(
  <React.StrictMode>
    {!(window as any).Modernizr.getusermedia &&
      <div>You browser is not supported</div>
    }
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
