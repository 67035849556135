// This file was automatically generated.  Do not modify.
 const strings = {
OFFLINE : "Offline",
LOG_OUT : "Log af",
SETTINGS : "Indstillinger",
EDIT_USERNAME : "Rediger brugernavn",
SAVE : "Gem",
DELETE_CONTACT : "Slet kontakt",
CREATE_NEW_USER : "Opret ny bruger",
EYES : "Øjne",
WEBCAM : "Webcam",
USERNAME : "Brugernavn",
PASSWORD : "Kodeord",
EDIT_LANGUAGE : "Vælg sprog",
REJECTED_CALL : "Opkaldet blev afvist",
TERMS_AND_PRIVACY : "Ved at fortsætte accepterer du vores {terms} og {privacy}",
TERMS_AND_CONDITIONS : "vilkår og betingelser",
PRIVACY_POLICY : "privatlivspolitik",
AUTH_HEADING : "Log ind eller opret en bruger",
VERSION : "Version: ",
CONTINUE : "Fortsæt",
SIGN_IN : "Log ind",
REGISTER_ROBOT : "Registrer robot",
LICENSE_KEY : "Licensnøgle",
REGISTER : "Registrer",
LOADING : "Loading",
CREATE : "Opret",
WRONG_ROBOT : "Du er forbundet til en forkert robot",
NEW_SPIN : "Du er nu logget ind på robot: {uid} {br}Men du er forbundet til {spinID}{br}Hvis du vil logge ind på {spinID}skal du først logge ud",
SIGN_OUT : "Log ud",
ENABLE_WEBCAM : "Hvordan skal {name} kunne se dig?",
VIDEO_NOT_ACTIVE : "Venter på at {contactName} svarer",
LOADING_VIDEO : "Henter video",
DELETE_CONFIRMATION : "Er du sikker?",
YES : "Ja",
NO : "Nej",
EDITTING : "Redigerer",
UPDATE_NICKNAME : "Gem kælenavn",
NICKNAME : "Kælenavn",
CHOOSE_LANGUAGE : "Vælg sprog",
HERE : "her",
NO_IMAGE : "Intet billede",
INPUT_MIN_LENGTH : "{input} skal være på mindst {n} karakterer",
PASSWORD_MUST_CONTAIN_LETTER : "Password skal indeholde et bogstav",
PASSWORD_MUST_CONTAIN_NUMBER : "Password skal indeholde et tal",
INVALID_PASSWORD : "Password er ikke gyldigt",
PASSWORD_RESET : "Nulstil Password",
RESET_PASSWORD : "Nulstil password",
INVALID_CODE : "Koden er ikke gyldig",
FORGOT_PASSWORD : "Glemt kodeord? Klik ",
APP_OUTDATED : "Den installerede app er version: {version}{br}Den nyeste version er: {currentVersion}{br}Du bliver nødt til at opdatere til den nyeste version.",
GO_TO_STORE : "Gå til Play Butik",
CONFIRM : "Er du sikker?",
GOOD_JOB : "Godt gået!",
USER_CREATED_TEXT : "Du har lige lavet en ny bruger. Giv brugernavn og kodeord til den person der skal ringe op til robotten. Personen kan logge ind via ",
CHOOSE_TEACHER : "Vælg dette hvis du vil give en elev{br}adgang til at fjernstyre robotten",
CHOOSE_STUDENT : "Vælg dette hvis du vil{br}fjernstyre robotten",
STUDENT : "Elev",
TEACHER : "Lærer",
NO_ACCOUNT_OR_FORGOT_PASS : "Ingen bruger eller glemt kodeord?",
CONTACT_TEACHER : "Kontakt din lærer",
TYPE_PINCODE : "Indtast pinkode",
REJECT : "Afvis",
VOICE_ONLY : "Kun stemme",
VIDEO : "Video",
INCOMING_CALL_FROM : "Indgående opkald fra",
CALL_ROBOT_HEADLINE : "Ring op til robotten for at styre den",
VIDEO_CALL : "Video opkald",
VOICE_CALL : "Stemme opkald",
YOU_CAN_BE_SEEN : "Du bliver set",
YOU_CANNOT_BE_SEEN : "Du kan ikke blive set",
NEW_PIN : "Vælg en ny pinkode til robotten",
CONFIRM_PIN : "Bekræft pinkode",
ADD_STUDENT : "Tilføj elev",
STUDENTS : "Elever",
CONNECT_TO_ROBOT : "Forbind robotten med USB kabel",
MIC_IS_MUTE : "Mikrofonen er slået fra",
MIC_IS_UNMUTE : "Mikrofonen er slået til",
SPIN_CAN_DRIVE : "Robotten kan køre",
SPIN_CAN_TURN : "Robtten kan kun dreje",
CONFIRM_SETTING_TRUSTED_CALLER : "Når denne elev ringer op, vil opkaldet automatisk blive accepteret. Er du sikker på at du vil tillade det?",
TRUSTED_CALLER : "Accepter opkald automatisk",
SPIN_BATTERY_LEVEL : "Spin batteri: ",
UPDATE_APP : "Opdater app",
USERNAME_CHARACTER_RESTRICTION : "Brugernavn må ikke indeholde [, ], /, @, ., *, ´, `,?,~,%",
REVERSE_SPIN_CONTROL : "Robotten er i omvendt styringstilstand",
NORMAL_SPIN_CONTROL : "Robotten er i normal styringstilstand",
SPIN_NOT_CONNECTED : "USB er ikke forbundet",
COPY : "//to do",
TEXT_COPIED : "//to do",
LICENSE_REQUIRED : "//to do",
DONT_HAVE_LICENSE : "//to do",
GET_ONE : "//to do",
SPIN_CONNECTED_TO : "//to do",
PIN_RESET : "//to do",
RESET_PIN : "//to do",
RESET_NEW_PIN : "//to do",
FORGOT_PIN : "Glemt PIN? Klik ",
WRONG_PIN : "//to do",
CONFIRM_REQUIRED : "//to do",
PINS_DONT_MATCH : "//to do",
PIN_REQUIRED : "//to do",
}
export default strings
