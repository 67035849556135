const currentBuild = process.env.REACT_APP_MODE
const license = process.env.REACT_APP_LICENSE_MODE
const in_electron = process.env.REACT_APP_IN_ELECTRON

const config = {
    isAndroid: currentBuild === "ANDROID",
    isWeb: currentBuild === "WEB",
    isPro: license === "PRO",
    isConsumer: license === "CONSUMER",
    inElectron: in_electron === "YES"
}

export default config
