import { createSlice } from '@reduxjs/toolkit'
import store, { storeInjectReducer } from '../store'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const storeKey = 'android'

interface IState {
    ownSpinStatus: boolean,
    version: string,
    hasFocus: boolean,
    id: string,
    spinBatteryLevel: number | "unknown"
}

interface ISelectorState {
    [key: string]: IState
}

const androidSlice = createSlice({
    name: storeKey,
    initialState: {
        ownSpinStatus: false,
        version: "unknown",
        hasFocus: false,
        id: "",
        spinBatteryLevel: "unknown"
    },
    reducers: {
        setVersion(state, action) { state.version = action.payload },
        setFocus(state, action) { state.hasFocus = action.payload },
        setSpinConnectionStatus(state, action) { state.ownSpinStatus = action.payload },
        setID(state, action) { state.id = action.payload },
        setspinBatteryLevel(state, action) { state.spinBatteryLevel = action.payload }
    }
})

export const { actions, reducer } = androidSlice

storeInjectReducer(storeKey, reducer)

// --------------------------- TOP LEVEL SELECTORS ----------------------------------

const selectID = (state: ISelectorState) => state[storeKey].id
const selectSpinConnectionState = (state: ISelectorState) => state[storeKey].ownSpinStatus
export const selectVersion = (state: ISelectorState) => state[storeKey].version
const selectSpinBatteryLevel = (state: ISelectorState) => state[storeKey].spinBatteryLevel

// ---------------------------- SELECTORS -------------------------- // 

const getVersionSelector = createSelector(selectVersion, version => version)
export const useVersionSelector = () => useSelector(getVersionSelector)
export const getSpinID = createSelector(selectID, id => id)
export const useSpinID = () => useSelector(getSpinID)
export const getSpinConnectionState = createSelector(selectSpinConnectionState, state => state)
export const useSpinConnectionState = () => useSelector(getSpinConnectionState)
const getSpinBatteryLevel = createSelector(selectSpinBatteryLevel, batteryLevel => batteryLevel)
export const useSpinBatteryLevel = () => useSelector(getSpinBatteryLevel)

// --------------------------- ACTIONS ------------------------ //

export const setVersion = (version: string) => store.dispatch(actions.setVersion(version))
export const setFocus = (focus: boolean) => store.dispatch(actions.setFocus(focus))
export const setSpinConnectionStatus = (status: boolean) => store.dispatch(actions.setSpinConnectionStatus(status))
export const setSpinID = (id: string) => store.dispatch(actions.setID(id))
export const setSpinBatteryLevel = (level: number | "unknown") => store.dispatch(actions.setspinBatteryLevel(level))
