import { useEffect, useState } from 'react'
import { getVideoStream as getVid, getAudioStream as getAud, stopStream } from './videoservice'

const streams = {
    ownVideo: new MediaStream(),
    ownAudio: new MediaStream(),
    remoteVideo: new MediaStream(),
    remoteAudio: new MediaStream()
}

let stoppedVideo = false;
let stoppedAudio = false;

interface IsubsciptionCallback {
    (stream: MediaStream): Promise<any> | void;
}

interface subscribtionType {
    onOwnAudio?: IsubsciptionCallback;
    onOwnVideo?: IsubsciptionCallback;
    onRemoteAudio?: IsubsciptionCallback;
    onRemoteVideo?: IsubsciptionCallback;
}

interface subscriptionsObjectType {
    [id: string]: subscribtionType;
}

const subscriptions: subscriptionsObjectType = {};

export const unSubscribe = (id: string) => {
    delete subscriptions[id]
}

export const subscribeOwnVideo = async (id: string, callback: IsubsciptionCallback) => {
    await callback(streams.ownVideo)
    if (subscriptions[id]) {
        subscriptions[id].onOwnVideo = callback
    } else {
        subscriptions[id] = {}
        subscriptions[id].onOwnVideo = callback
    }
}

export const subScribeOnAudio = async (id: string, callback: IsubsciptionCallback) => {
    await callback(streams.ownAudio)
    if (subscriptions[id]) {
        subscriptions[id].onOwnAudio = callback
    } else {
        subscriptions[id] = {}
        subscriptions[id].onOwnAudio = callback
    }
}

export const subscribeRemoteVideo = (id: string, callback: IsubsciptionCallback) => {
    callback(streams.remoteVideo)
    if (subscriptions[id]) {
        subscriptions[id].onRemoteVideo = callback
    } else {
        subscriptions[id] = {}
        subscriptions[id].onRemoteVideo = callback
    }
}

export const subscribeRemoteAudio = (id: string, callback: IsubsciptionCallback) => {
    callback(streams.remoteAudio)
    if (subscriptions[id]) {
        subscriptions[id].onRemoteAudio = callback
    } else {
        subscriptions[id] = {}
        subscriptions[id].onRemoteAudio = callback
    }
}

export const getOwnVideoStream = async (res: number, facing: "environment" | "user") => {
    console.log("getting own video")
    stoppedVideo = false;
    if (streams.ownVideo.active) {
        streams.ownVideo.getTracks().forEach(track => {
            track.enabled = true
        })
        return true
    } else {
        const newVideoStream = await getVid(res, facing)
        console.log("new videostream:")
        console.log(newVideoStream)
        if (newVideoStream) {
            stopStream(streams.ownVideo)
            streams.ownVideo = newVideoStream
            await dispatchOwnVideo(streams.ownVideo)
            if (stoppedVideo) {
                removeOwnVideo()
            }
            return true
        } else {
            return false
        }
    }

}

export const ownVideo = () => {
    return streams.ownVideo
}

export const diableOwnVideo = () => {
    if (streams.ownVideo) streams.ownVideo.getTracks().forEach(track => {
        track.enabled = false;
    })
}

export const removeOwnVideo = async () => {
    stopStream(streams.ownVideo)
    streams.ownVideo = new MediaStream();
    stoppedVideo = true;
    await dispatchOwnVideo(streams.ownVideo)
}

export const getOwnAudioStream = async () => {
    stoppedAudio = false;
    if (streams.ownAudio.active) {
        streams.ownAudio.getTracks().forEach(track => {
            track.enabled = true;
        })
        return true
    } else {
        const newAudioStream = await getAud()
        console.log("getting own audio")
        if (newAudioStream) {
            stopStream(streams.ownAudio)
            streams.ownAudio = newAudioStream
            await dispatchOwnAudio(streams.ownAudio)
            if (stoppedAudio) {
                removeOwnAudioStream()
            }
            return true
        } else {
            return false
        }
    }
}

export const ownAudio = () => {
    return streams.ownAudio
}

export const disableOwnAudioStream = () => {
    if (streams.ownAudio) streams.ownAudio.getTracks().forEach(track => {
        track.enabled = false;
    })
}

export const removeOwnAudioStream = async () => {
    stopStream(streams.ownAudio)
    streams.ownAudio = new MediaStream();
    stoppedAudio = true;
    await dispatchOwnAudio(streams.ownAudio)
}

export const setRemoteVideo = (video: MediaStream) => {
    stopStream(streams.remoteVideo)
    streams.remoteVideo = video
    dispatchRemoteVideo(streams.remoteVideo)
}

export const setRemoteAudio = (audio: MediaStream) => {
    stopStream(streams.remoteAudio)
    streams.remoteAudio = audio
    dispatchRemoteAudio(streams.remoteAudio)
}


const dispatchOwnVideo = async (video: MediaStream) => {
    let promises: (Promise<any> | void)[] = []
    Object.values(subscriptions).forEach(({ onOwnVideo }) => {
        if (onOwnVideo) promises.push(onOwnVideo(video))
    })
    await Promise.all(promises)
}

const dispatchOwnAudio = async (audio: MediaStream) => {
    let promises: (Promise<any> | void)[] = []
    Object.values(subscriptions).forEach(({ onOwnAudio }) => {
        if (onOwnAudio) promises.push(onOwnAudio(audio))
    })
    await Promise.all(promises)
}

const dispatchRemoteVideo = async (video: MediaStream) => {
    let promises: (Promise<any> | void)[] = []
    Object.values(subscriptions).forEach(({ onRemoteVideo }) => {
        if (onRemoteVideo) promises.push(onRemoteVideo(video))
    })
    await Promise.all(promises)
}

const dispatchRemoteAudio = async (audio: MediaStream) => {
    let promises: (Promise<any> | void)[] = []
    Object.values(subscriptions).forEach(({onRemoteAudio}) => {
        if (onRemoteAudio) promises.push(onRemoteAudio(audio))
    })
    await Promise.all(promises)
}

export const useRemoteVideo = () => {
    const [remoteVideo, setremoteVideo] = useState(streams.remoteVideo)
    useEffect(() => {
        let id = String(Math.round(Math.random() * 1000000))
        while (Object.keys(subscriptions).includes(id)) {
            id = String(Math.round(Math.random() * 1000000))
        }
        subscribeRemoteVideo(id, setremoteVideo)
        return () => { unSubscribe(id) }
    }, [])
    return remoteVideo
}

export const useRemoteAudio = () => {
    const [remoteAudio, setremoteAudio] = useState(streams.remoteAudio)
    useEffect(() => {
        let id = String(Math.round(Math.random() * 1000000))
        while (Object.keys(subscriptions).includes(id)) {
            id = String(Math.round(Math.random() * 1000000))
        }
        subscribeRemoteAudio(id, audio => { setremoteAudio(audio) })
        return () => { unSubscribe(id) }
    }, [])
    return remoteAudio
}


export const useOwnVideo = () => {
    const [ownVideo, setOwnVideo] = useState(streams.ownVideo)
    useEffect(() => {
        let id = String(Math.round(Math.random() * 1000000))
        while (Object.keys(subscriptions).includes(id)) {
            id = String(Math.round(Math.random() * 1000000))
        }
        subscribeOwnVideo(id, video => { setOwnVideo(video) })
        return () => { unSubscribe(id) }
    }, [])
    return ownVideo
}
