import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAvailableLanguage, setLang, availabelLanguages, useCurrentLanguage } from 'store/slices/languageSlice'
import { setLanguage } from 'services/newFirebase'
import { useTranslatedString } from 'store/slices/languageSlice'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { useChangeLanguage, setChangeLanguage, setDrawer } from 'store/slices/showables'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "8% 8% 8% 0%",
        padding: "30px 30px 15px 30px"
    },
    greyTxt: {
        color: "grey",
        fontWeight: 600,
        fontSize: 23,
        padding: "20px 0px",
    },

    menu: {
        maxHeight: 200
    }
})

const ChangeLanguage = () => {
    const currentLanguage = useCurrentLanguage()
    const changeLanguage = useTranslatedString("EDIT_LANGUAGE")
    const availableLanguages = useAvailableLanguage()
    const classes = useStyles()

    const open = useChangeLanguage()

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLanguage(event.target.value)
        setLang(event.target.value as string)
        handleClose()
    }
    
    const languagesList = availableLanguages.map((value: availabelLanguages) =>
        <MenuItem value={value}>{value}</MenuItem>
    )

    const handleClose = () => {
        setChangeLanguage(false)
        setDrawer(false)
    }

    return (
        <Dialog classes={{ paper: classes.container }} transitionDuration={400} open={open} onClose={handleClose} aria-labelledby="change-language-dialog">
            <DialogTitle ><Typography align="center" className={classes.greyTxt}>{changeLanguage}</Typography></DialogTitle>
            <DialogContent>
                <Select MenuProps={{ classes: { paper: classes.menu } }} variant="outlined" value={currentLanguage}
                    onChange={handleChange}>
                    {languagesList}
                </Select>
            </DialogContent>
        </Dialog >
    )
}

export default ChangeLanguage