// This file was automatically generated.  Do not modify.
 const strings = {
OFFLINE : "Offline",
LOG_OUT : "Log out",
SETTINGS : "Settings",
EDIT_USERNAME : "Edit username",
SAVE : "Save",
DELETE_CONTACT : "Delete contact",
CREATE_NEW_USER : "Create new user",
EYES : "Eyes",
WEBCAM : "Webcam",
USERNAME : "Username",
PASSWORD : "Password",
EDIT_LANGUAGE : "Change language",
REJECTED_CALL : "Call was rejected",
TERMS_AND_PRIVACY : "By continuing you accept our {terms} and {privacy}",
TERMS_AND_CONDITIONS : "terms and conditions",
PRIVACY_POLICY : "privacy policy",
AUTH_HEADING : "Please login or create an account",
VERSION : "Version: ",
CONTINUE : "Continue",
SIGN_IN : "Sign in",
REGISTER_ROBOT : "Register Robot",
LICENSE_KEY : "License key",
REGISTER : "Register",
LOADING : "Loading",
CREATE : "Create",
WRONG_ROBOT : "You have connected to the wrong robot",
NEW_SPIN : "You are now logged in to the robot: {uid} {br} But you are connected to {spinID} {br} If you want to sign in to {spinID}then sign out first",
SIGN_OUT : "Sign out",
ENABLE_WEBCAM : "How should {name} see you?",
VIDEO_NOT_ACTIVE : "Waiting for {contactName} to reply",
LOADING_VIDEO : "Loading video",
DELETE_CONFIRMATION : "Are you sure?",
YES : "Yes",
NO : "No",
EDITTING : "Editting",
UPDATE_NICKNAME : "Update nickname",
NICKNAME : "Nickname",
CHOOSE_LANGUAGE : "Choose language",
HERE : "here",
NO_IMAGE : "No image",
INPUT_MIN_LENGTH : "{input} should be at least {n} characters",
PASSWORD_MUST_CONTAIN_LETTER : "Password must contain a letter",
PASSWORD_MUST_CONTAIN_NUMBER : "Password must contain a number",
INVALID_PASSWORD : "Invalid password",
PASSWORD_RESET : "Password Reset",
RESET_PASSWORD : "Reset password",
INVALID_CODE : "Invalid code",
FORGOT_PASSWORD : "Forgot password? Click ",
APP_OUTDATED : "The installed version of this app is: {version} {br}The newest version is: {currentVersion}{br}Please update to the newest version.",
GO_TO_STORE : "Go to Play Store",
CONFIRM : "Are you sure?",
GOOD_JOB : "Good job!",
USER_CREATED_TEXT : "You just made a user. Now give the username and password to the person who will be calling this robot. The person can login via",
CHOOSE_TEACHER : "Choose this if you want to grant a student{br}permission to remote control the robot",
CHOOSE_STUDENT : "Choose this if you want to{br}remote control the robot",
STUDENT : "Student",
TEACHER : "Teacher",
NO_ACCOUNT_OR_FORGOT_PASS : "No account or forgot password?",
CONTACT_TEACHER : "Contact your teacher",
TYPE_PINCODE : "Enter PIN",
REJECT : "Reject",
VOICE_ONLY : "Voice only",
VIDEO : "Video",
INCOMING_CALL_FROM : "Incoming call from ",
CALL_ROBOT_HEADLINE : "Call robot to start controlling it",
VIDEO_CALL : "Video call",
VOICE_CALL : "Voice call",
YOU_CAN_BE_SEEN : "You can be seen",
YOU_CANNOT_BE_SEEN : "You cannot be seen",
NEW_PIN : "Choose a pincode for the robot",
CONFIRM_PIN : "Confirm pincode",
ADD_STUDENT : "Add student",
STUDENTS : "Students",
CONNECT_TO_ROBOT : "Connect robot with USB cable",
MIC_IS_MUTE : "Microphone is muted",
MIC_IS_UNMUTE : "Microphone is enabled",
SPIN_CAN_DRIVE : "The robot can drive",
SPIN_CAN_TURN : "The robot can only turn",
CONFIRM_SETTING_TRUSTED_CALLER : "When this student calls, the call will automatically go through. Are you sure you want this?",
TRUSTED_CALLER : "Automatically accept calls",
SPIN_BATTERY_LEVEL : "Spin battery: ",
UPDATE_APP : "Update app",
USERNAME_CHARACTER_RESTRICTION : "Username cannot contain [, ], /, @, ., *, ´, `,?,~,%",
REVERSE_SPIN_CONTROL : "The robot is in reverse control",
NORMAL_SPIN_CONTROL : "The robot is in normal control mode",
SPIN_NOT_CONNECTED : "USB is not connected",
COPY : "Copy",
TEXT_COPIED : "Copied to the clipboard!",
LICENSE_REQUIRED : "License key is required",
DONT_HAVE_LICENSE : "Don't have a Fable Connect PRO license",
GET_ONE : "Get one",
SPIN_CONNECTED_TO : "You are now connected to",
PIN_RESET : "PIN reset",
RESET_PIN : "Reset PIN",
RESET_NEW_PIN : "New PIN",
FORGOT_PIN : "Forgot PIN? Click ",
WRONG_PIN : "PIN is wrong",
CONFIRM_REQUIRED : "Confirm PIN is required",
PINS_DONT_MATCH : "Pincodes do not match",
PIN_REQUIRED : "PIN is required",
}
export default strings
