import React, { useState, Suspense, useEffect } from 'react';

import { logError } from 'services/newFirebase'

import config from './config/config'
import splash from 'videos/FC_PRO_Splash_Screen.mp4'

import ChangeLanguage from 'components/ChangeLanguage'
import Loading from 'components/loading'
import MyErrorBoundary from 'components/ErrorBoundary'

import { StylesProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles'

import './services/newFirebase'

window.addEventListener('focus', () => {
  //console.log("hasfocus")
})

window.addEventListener('blur', () => {
  //console.log("hasblur")
})

window.onerror = (_message, _source, lineno, colno, error) => {
  if (error) {
    logError(error.message + " at: " + (lineno || "") + ", " + (colno || ""), error.stack)
  }
}

const useStyles = makeStyles({
  video: {
    maxHeight: config.isAndroid ? "100vh" : "70vh",
    maxWidth: config.isAndroid ? "100vw" : "70vw",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    position: "absolute",
    margin: "auto",
    backgroundColor: "white"
  },
  div: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: "0px",
    left: "0px",
    zIndex: 10000
  },
  version: {
    alignSelf: "flex-end",
    color: "grey"
  }
})
//@ts-ignore
const ToShow = React.lazy(() => {
  if (config.isAndroid) {
    return import('./Android')
  } else if (config.isWeb) {
    return import('./Web')
  }
})


const App = () => {
  const [loading, setLoading] = useState(true)
  const classes = useStyles()

  useEffect(() => {
    const id = window.setTimeout(() => {
      setLoading(false)
    }, 4000)
    return () => {
      clearTimeout(id)
    }
  }, [])

  return (
    <StylesProvider injectFirst>
      <MyErrorBoundary>
        <React.Fragment>
          <Loading />
          <ChangeLanguage />
          {(process.env.NODE_ENV === "production" && loading) &&
            <div className={classes.div}>
              <video
                autoPlay
                onEnded={() => { setLoading(false) }}
                playsInline
                muted
                className={classes.video}>
                <source src={splash} type="video/mp4" />
              </video>
            </div>
          }
          <Suspense fallback={<div></div>}>
            <ToShow />
          </Suspense>
        </React.Fragment>
      </MyErrorBoundary>
    </StylesProvider>
  )
}

export default App
