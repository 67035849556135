import React from 'react'
import { useLoading } from 'store/slices/showables'
import loadingVid from 'videos/logo_orange.webm'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    videoContainer: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(100,100,100,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100000000

    },
    video: {
        opacity: "1.0 !important"
    }
}))

const Loading = () => {
    const classes = useStyles()
    const loading = useLoading()
    return (
        <>
            {loading &&
                <div className={classes.videoContainer}>
                    <video className={classes.video} playsInline autoPlay muted loop src={loadingVid} />
                </div>}
        </>
    )
}

export default Loading