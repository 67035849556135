import config from 'config/config'

interface IFirebaseCallResult {
    msg?: string | null;
    type?: "error" | "success" | null;
    code?: string | null;
    data?: any | null;
}

interface IFirebaseFunction {
    call(inputdata: object | undefined): Promise<IFirebaseCallResult>
}

class FirebaseFunction implements IFirebaseFunction {
    private name;
    constructor(functionName: string) {
        this.name = functionName;
    }

    async call(input: object): Promise<IFirebaseCallResult> {
        //try {
            if (config.isWeb) {
                return await (await import('./webFirebase')).functions.httpsCallable(this.name)(input);
            }
            else if (config.isAndroid) {
                return await (await import('./reactToAndroid')).getCallable(this.name)(input);
            }
            else {
                return {
                    type: "error"
                }
            }
        // } catch (error) {
        //     return {
        //         type: "error"
        //     }
        // }
    }

}

export default FirebaseFunction