import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'
import store, { storeInjectReducer } from '../store'
import { getUserType, getContacts, getTrustedCallerStatus } from 'store/slices/firebaseSlice'
import { setAvatar } from 'store/slices/avatarSlice'
import { useSelector } from 'react-redux'
import Webrtc from '../../services/webrtc'
import {
    placeCall as fbPlaceCall,
    localAcceptCall as fblocalAcceptCall,
    localHangUp as fbLocalHangup,
    localRejectCall as fbLocalRejectCall,
    remoteHangup as fbRemoteHangup,
    incommingCall as fbIncommingCall,
    sendSignalling
} from 'services/newFirebase'

import { removeOwnAudioStream, removeOwnVideo } from 'services/streams'
import { setIsMicMute } from 'store/slices/inCallSlice'
import { stopListening } from 'services/keyListener'
import CallLogging, { DummyCallLoggin } from 'services/callLogging'
import config from 'config/config'

const log = (...args: string[]) => {
    let res = "WEBRTC SLICE: "
    args.forEach(arg => {
        res += arg + " "
    })
    console.log(res)
}

let counter = 0
let _webrtcSessionID = ""

let webrtcs = {} as any

const storeKey = 'webrtc'

interface IState {
    remoteUID: null | string,
    remoteSessionID: null | string,
    callStatus: null | string,
    pcReady: boolean,
    polite: boolean,
    outgoingCall: boolean
}
interface ISelectorState {
    [key: string]: IState
}
const initialState: IState = {
    remoteUID: null,
    remoteSessionID: null,
    callStatus: "NOT_IN_CALL",
    pcReady: false,
    polite: false,
    outgoingCall: false
}

const webrtcSlice = createSlice({
    name: storeKey,
    initialState,
    reducers: {
        beforeCall(state) { state.callStatus = "BEFORE_CALL" },
        setRemoteUID(state, action) { state.remoteUID = action.payload },
        setRemoteSessionID(state, action) { state.remoteSessionID = action.payload },
        reset: () => initialState,
        remoteRejectCall(state) { state.callStatus = "REMOTE_REJECT" },
        incommingCall(state, action) {
            state.callStatus = "INCOMMING_CALL"
            state.remoteUID = action.payload.remoteUID
            state.remoteSessionID = action.payload.sessionID
        },
        callAccepted(state, action) {
            state.remoteSessionID = action.payload.sessionID
        },
        inCall(state) { state.callStatus = "IN_CALL" },
        setPolite(state, action) { state.polite = action.payload },
        setPCReady(state, action) { state.pcReady = action.payload },
        setOutgoingCall(state, action) {
            state.outgoingCall = action.payload
        }
    }
})

export const { actions, reducer } = webrtcSlice

storeInjectReducer(storeKey, reducer)

// --------------------------------- TOP LEVEL SELECTOR ------------------------------------ //

const selectRemoteUID = (state: ISelectorState) => state[storeKey].remoteUID
const selectCallStatus = (state: ISelectorState) => state[storeKey].callStatus
const selectPolite = (state: ISelectorState) => state[storeKey].polite
const selectRemoteSessionID = (state: ISelectorState) => state[storeKey].remoteSessionID
const selectOutgoingCall = (state: ISelectorState) => state[storeKey].outgoingCall



// ------------------------------------- SELECTORS -------------------------------------------- // 

const getPolite = createSelector(selectPolite, polite => polite)
export const usePolite = () => useSelector(getPolite)
const getCallStatus = createSelector(selectCallStatus, status => status)
export const useCallStatus = () => useSelector(getCallStatus)
export const getRemoteUID = createSelector(selectRemoteUID, uid => uid)
export const useRemoteUID = () => useSelector(getRemoteUID)

const getOutgoingCall = createSelector(selectOutgoingCall, outgoingCall => outgoingCall)
export const useOutgoingCall = () => useSelector(getOutgoingCall)

const remoteUIDHelper = () => {
    const remoteUID = selectRemoteUID(store.getState())
    if (!remoteUID) {
        throw Error("no UID")
    }
    return remoteUID
}

// ----------------------------------------------------- DISPATCHES ---------------------------------------------------

export const setPolite = (polite: boolean) => store.dispatch(actions.setPolite(polite))
export const setupPC = async (initiator: boolean, relayOnly: boolean, webrtcSessionID?: string) => {
    try {
        const state = store.getState()
        const remoteUID = selectRemoteUID(state)

        if (webrtcs[remoteUID + String(counter)]) {
            await webrtcs[remoteUID + String(counter)].close()
            delete webrtcs[remoteUID + String(counter)]
            counter += 1
        }

        const isRobot = getUserType(state) === "robot";

        const callLogging = isRobot ? new CallLogging(remoteUID!) : new DummyCallLoggin()
        const webrtc = new Webrtc(remoteUID!, initiator, callLogging, webrtcSessionID)
        webrtcs[remoteUID + String(counter)] = webrtc
        _webrtcSessionID = webrtc.sessionID
        await webrtcs[remoteUID + String(counter)].init(relayOnly)

        if (!state.incall.local.isMicMute) {
            log("mic was not mute")
            await setIsMicMute(false)
        } else {
            log("mic was mute")
        }

        if (isRobot) {
            await setAvatar("VIDEO")
        }
        return true
    } catch (error) {
        log("ERROR: ", error)
        reset()
        return false
    }
}
export const reset = async () => {
    const remoteUID = selectRemoteUID(store.getState())
    stopListening(remoteUID || "");
    if (webrtcs[remoteUID + String(counter)]) {
        await webrtcs[remoteUID + String(counter)].close()
    }
    delete webrtcs[remoteUID + String(counter)]
    counter += 1
    await removeOwnAudioStream()
    await removeOwnVideo()
    setAvatar(null)
    fbLocalRejectCall(selectRemoteUID(store.getState()), _webrtcSessionID)
    _webrtcSessionID = ""
    store.dispatch(actions.reset())
}

export const setRemoteUID = (uid: string) => store.dispatch(actions.setRemoteUID(uid))

export const placeCall = async () => {
    log("placing call")

    store.dispatch(actions.inCall())
    store.dispatch(actions.setOutgoingCall(true))
    await setupPC(true, false)
    if (selectCallStatus(store.getState()) !== "NOT_IN_CALL") fbPlaceCall(remoteUIDHelper(), _webrtcSessionID)
}


export const localAccept = async () => {
    log("accepting call")
    store.dispatch(actions.inCall())
    await setupPC(false, false, _webrtcSessionID)
    const remoteUID = selectRemoteUID(store.getState())
    fblocalAcceptCall(remoteUID, _webrtcSessionID)
    webrtcs[remoteUID + String(counter)].startCall()
}

export const localHangup = () => {
    fbLocalHangup(selectRemoteUID(store.getState()), _webrtcSessionID)
    reset()
}
export const localReject = () => {
    fbLocalRejectCall(selectRemoteUID(store.getState()), _webrtcSessionID)
    reset();
}

export const beforeCall = () => {
    store.dispatch(actions.beforeCall())
}

type ControlObject = {
    x: number,
    y: number
}
export const setControl = (control: ControlObject, remoteUID: string) => {
    if (webrtcs[remoteUID + String(counter)]) {
        webrtcs[remoteUID + String(counter)].setControlStatus(control)
    }
}

export const requestRestart = async () => {
    console.log("I SHOULD REQUEST A RESTART!!!!!!!!!!!!!!!!!!!!!!!")
    store.dispatch(actions.setPCReady(false))
    await setupPC(true, false)
    sendSignalling("restart-requested", remoteUIDHelper(), _webrtcSessionID)
}

type DataObject = {
    uid: string,
    msg: string,
    sessionID: string,
    webrtcSessionID?: string
}
export const signalling = async (data: DataObject) => {
    const state = store.getState()
    const currentRemoteUID = getRemoteUID(state)
    const contacts = getContacts(state)
    const currentRemoteSessionID = selectRemoteSessionID(state)
    const callStatus = selectCallStatus(state)
    const { uid, msg, sessionID, webrtcSessionID } = data
    const remoteUID = uid
    log("incoming signalling: ", JSON.stringify(data))
    const signalIsFromAContact = Object.keys(contacts).includes(remoteUID)
    log("sessionID", sessionID, "currentSessionID", currentRemoteSessionID!, "currentUID", currentRemoteUID!)
    if (signalIsFromAContact) {
        log("signal was from contact")
        if (msg === "connect" && callStatus === "NOT_IN_CALL") {
            if(webrtcSessionID) _webrtcSessionID = webrtcSessionID
            const trustedCaller = getTrustedCallerStatus(remoteUID)
            if (trustedCaller) {
                if(config.isAndroid) {
                    (window as any).Android.playPling()
                }
                store.dispatch(actions.setRemoteUID(remoteUID))
                store.dispatch(actions.setRemoteSessionID(sessionID))
                localAccept()
            } else {
                fbIncommingCall()
                store.dispatch(actions.incommingCall({ remoteUID: remoteUID, sessionID: sessionID }))
            }
        }
        if (msg === "accepted" && callStatus !== "NOT_IN_CALL") {
            store.dispatch(actions.setOutgoingCall(false))
            store.dispatch(actions.callAccepted({ sessionID: sessionID }))
        }

        if (msg === "rejected" && currentRemoteUID === remoteUID && !currentRemoteSessionID) {
            store.dispatch(actions.setOutgoingCall(false))
            store.dispatch(actions.remoteRejectCall())
        }

        if (sessionID === currentRemoteSessionID && currentRemoteUID === remoteUID) {
            if (msg === "hangup") {
                fbRemoteHangup()
                reset()
            }

            if (msg === "restart-requested") {
                store.dispatch(actions.setPCReady(false))
                if(webrtcSessionID) _webrtcSessionID = webrtcSessionID
                await setupPC(false, false, _webrtcSessionID)
                window.setTimeout(() => {
                    sendSignalling("restart-accepted", currentRemoteUID, _webrtcSessionID)
                }, 5000)
            }
            if (msg === "restart-accepted") {
                webrtcs[remoteUID + String(counter)].startCall()
            }
        }
    }
}