import { gotRemoteState, localSubscribe } from 'store/slices/inCallSlice'
import { requestRestart } from 'store/slices/webrtcSlice'
import adapter from 'webrtc-adapter'
import signalling from '../signalling/signalling'
import { isEqual } from 'lodash'
import { IState } from 'store/slices/inCallSlice'

import config from 'config/config'

import { subscribeOwnVideo, subScribeOnAudio, setRemoteAudio, setRemoteVideo, unSubscribe } from './streams'

import { ICallLogging } from 'services/callLogging'
import { nanoid } from 'nanoid'

import log from 'loglevel'
import { Unsubscribe } from 'redux'

type ControlObject = {
    x: number,
    y: number
}

const logger = log.getLogger("webrtc");
logger.setLevel("DEBUG")


const browser = adapter.browserDetails.browser
const version = adapter.browserDetails.version
const supportsOptionalSDP = !(
    (browser === "safari") ||
    (browser === "chrome" && version && version < 80) ||
    (browser === "firefox" && version && version < 75)
)
const restartICESupported = !(
    (browser === "safari") ||
    (browser === "firefox" && version && version < 70) ||
    (browser === "chrome" && version && version < 77)
)

interface IsendersObject {
    audio?: any;
    video?: any;
}

class Webrtc {
    private pc?: RTCPeerConnection;
    remoteUID: string;
    private makingOffer: boolean;
    private ignoreOffer: boolean;
    private polite: boolean;
    private dataChannel: RTCDataChannel | undefined;
    private stateIntervalID: number;
    private controlIntervalID: number;
    private controlStatus: ControlObject;
    private hasNegotiationNeededCallback: boolean;
    private senders: IsendersObject;
    private callLogging: ICallLogging;
    private subscriber: Unsubscribe | null;
    private localState: IState;
    private lastRemoteState: IState;
    sessionID: string

    constructor(remoteUID: string, initiator: boolean, callLogging: ICallLogging, sessionID?: string) {
        if(!initiator && !sessionID) throw new Error("not the initiator and no session id in constructor")
        this.sessionID = initiator ? nanoid() : sessionID || ""
        this.remoteUID = remoteUID
        this.makingOffer = false
        this.ignoreOffer = false
        this.polite = initiator

        this.stateIntervalID = 0;
        this.controlIntervalID = 0;

        this.controlStatus = { x: 0.0, y: 0.0 }
        this.hasNegotiationNeededCallback = false
        this.senders = {}
        this.callLogging = callLogging

        this.subscriber = null;
        this.localState = {
            spinConnected: false,
            avatarMode: null,
            handRaised: false,
            requestingRaisedHand: false,
            driveMode: "UNSAFE",
            spinID: null,
            isMicMute: false,
            userType: null,
        }

        this.lastRemoteState = {
            spinConnected: false,
            avatarMode: null,
            handRaised: false,
            requestingRaisedHand: false,
            driveMode: "UNSAFE",
            spinID: null,
            isMicMute: false,
            userType: null,
        }
    }

    onCandidate = async (candidate: string) => {
        if (this.pc?.remoteDescription?.type) {
            const candidateObj = JSON.parse(candidate)
            try {
                logger.log("pc is: " + this.pc?.connectionState)
                logger.log("adding ice candidate: " + candidate)
                await this.pc?.addIceCandidate(candidateObj);
            } catch (err) {
                logger.error(err.message)
                if (!this.ignoreOffer) {
                    if (this.pc) requestRestart()
                }
            }
        } else {
            logger.log("ignoring ice candidate")
        }
    }

    onDescription = async (description: string) => {
        try {
            const descriptionObj = JSON.parse(description)
            logger.debug("got" + descriptionObj.type)
            if (supportsOptionalSDP) {
                const offerCollision = (descriptionObj.type === "offer") && (this.makingOffer || this.pc?.signalingState !== "stable");
                this.ignoreOffer = !this.polite && offerCollision;

                if (this.ignoreOffer) {
                    logger.debug("ignoring offer")
                    return;
                }
                await this.pc?.setRemoteDescription(descriptionObj);
                if (descriptionObj.type === "offer") {
                    // @ts-ignore
                    await this.pc?.setLocalDescription();
                    logger.debug("sending answer")
                    signalling.sendDescription(this.pc?.localDescription, this.remoteUID, this.sessionID)
                }
            } else {
                if (descriptionObj.type === "offer" && this.pc?.signalingState !== "stable") {
                    if (!this.polite) return
                    await Promise.all([
                        this.pc?.setLocalDescription({ type: "rollback" }),
                        this.pc?.setRemoteDescription(descriptionObj)
                    ]);
                } else {
                    await this.pc?.setRemoteDescription(descriptionObj);
                }
                if (descriptionObj.type === "offer") {
                    await this.pc?.setLocalDescription(await this.pc.createAnswer());
                    logger.debug("sending answer")
                    signalling.sendDescription(this.pc?.localDescription, this.remoteUID, this.sessionID)
                }
            }
        } catch (error) {
            logger.error(error.message)
            if (this.pc) requestRestart()
        }
        await this.setupOnNegotiationNeeded()
    }

    init = async (relayOnly: boolean) => {
        let iceServers
        for (let i = 0; i < 3; i++) {
            iceServers = await signalling.getICE()
            logger.debug("server config: ", iceServers)
            if (iceServers) {
                break
            }
        }

        if (!iceServers) {
            logger.error("############## ERROR GETTING ICE ####################")
            return false
        }

        signalling.subscribe(this.remoteUID, this.sessionID, this.onCandidate, this.onDescription)

        // @ts-ignore
        this.pc = new RTCPeerConnection({ iceServers: [iceServers], iceTransportPolicy: relayOnly ? "relay" : "all", sdpSemantics: 'unified-plan' })
        this.callLogging.start(this.remoteUID);
        this.pc.oniceconnectionstatechange = (() => {
            logger.debug("ice connection state: ", this.pc?.iceConnectionState)
        })

        this.pc.onicecandidate = ({ candidate }) => {
            if (candidate) {
                signalling.sendCandidate(candidate, this.remoteUID, this.sessionID)
            } else {
                logger.debug("all ice sent")
            }
        }

        this.pc.ontrack = ({ track }) => {
            const stream = new MediaStream([track])
            if (track.kind === "video") {
                logger.debug("got remote video")
                setRemoteVideo(stream)
                stream.onremovetrack = () => {
                    setRemoteVideo(new MediaStream())
                }
            }
            if (track.kind === "audio") {
                logger.debug("got remote audio")
                setRemoteAudio(stream)
                stream.onremovetrack = () => {
                    setRemoteAudio(new MediaStream())
                }
            }
        }

        this.pc.oniceconnectionstatechange = () => {
            logger.debug("connection state: " + this.pc?.iceConnectionState)
            if (this.pc) {
                if (this.pc.iceConnectionState === "failed") {
                    if (restartICESupported) {
                        // @ts-ignore
                        this.pc.restartIce();
                    } else {
                        logger.debug("requesting restart because of failed ice")
                        if (this.pc) requestRestart()
                    }
                }
                else if (this.pc.iceConnectionState === "connected") {
                    this.callLogging.gotConnected()
                }
            }
        };

        this.pc.ondatachannel = ({ channel }) => {
            logger.debug("got datachannel")
            this.dataChannel = channel
            if (config.isAndroid) {
                import('services/reactToAndroid').then(({ gotRemoteControl }) => {
                    let lastControl: ControlObject;
                    if (this.dataChannel) {
                        this.dataChannel.onmessage = ({ data }) => {
                            const dataObj = JSON.parse(data)
                            const { state, control } = dataObj
                            if (state) {
                                this.onRemotestate(state)
                                gotRemoteState(state)
                            }
                            if (control && control !== lastControl) gotRemoteControl(control)
                        }
                    }
                })
            } else {
                this.dataChannel.onmessage = ({ data }) => {
                    const dataObj = JSON.parse(data)
                    const { state } = dataObj
                    if (state) {
                        this.onRemotestate(state)
                        gotRemoteState(state)
                    }
                }
            }
            this.startStateSync()
        }

        this.subscriber = localSubscribe((state: IState) => {
            if (this.localState.isMicMute !== state.isMicMute) {
                if (state.isMicMute) {
                    this.callLogging.ownMicMuted()
                } else {
                    this.callLogging.ownMicUnMuted()
                }
            }
            this.localState = state
        })

        await subScribeOnAudio(this.remoteUID, async (audio) => {
            await this.syncTrackPC(audio, "audio")
        })

        await subscribeOwnVideo(this.remoteUID, async (video) => {
            await this.syncTrackPC(video, "video")
        })
        return true
    }

    private onRemotestate(state: IState) {
        if (this.lastRemoteState.avatarMode !== state.avatarMode) {
            this.callLogging.avatarModeChanged(state.avatarMode || "unknown")
        }
        if (this.lastRemoteState.requestingRaisedHand !== state.requestingRaisedHand) {
            logger.log("raise hand: ", state.requestingRaisedHand)
            if (state.requestingRaisedHand) {
                this.callLogging.handRaised()
            } else {
                this.callLogging.handLowered()
            }
        }
        if (this.lastRemoteState.isMicMute !== state.isMicMute) {
            if (state.isMicMute) {
                this.callLogging.remoteMicMuted()
            } else {
                this.callLogging.remoteMicUnMuted()
            }
        }
        this.lastRemoteState = state
    }

    startCall = async () => {
        logger.debug("func: startCall")
        await this.addDataChannel()
        await this.createAndSendOffer()
        this.startStateSync()

        this.setupOnNegotiationNeeded()
    }


    createAndSendOffer = async () => {
        logger.debug("sending offer")
        try {
            if (!this.makingOffer) {
                this.makingOffer = true;
                await this.pc?.setLocalDescription(await this.pc.createOffer({ voiceActivityDetection: false, offerToReceiveAudio: true, offerToReceiveVideo: true }));
                signalling.sendDescription(this.pc?.localDescription, this.remoteUID, this.sessionID)
            }
        } catch (err) {
            logger.error(err.message);
            if (this.pc) requestRestart()
        } finally {
            this.makingOffer = false;
        }
    }

    setupOnNegotiationNeeded = async () => {
        if (!this.hasNegotiationNeededCallback) {
            this.hasNegotiationNeededCallback = true
            try {
                logger.debug("func: setupOnNegotiationNeeded")
                if (this.pc) {
                    this.pc.onnegotiationneeded = async () => {
                        logger.debug("negotiation needed")
                        await this.createAndSendOffer()
                    }
                }
            } catch (error) {
                logger.debug("ERROR:", error.message)
            }
        }
    }


    setControlStatus = (control: ControlObject) => {
        this.controlStatus = control
    }

    startStateSync = () => {
        if (!this.stateIntervalID) {
            this.stateIntervalID = window.setInterval(() => {
                if (this.dataChannel && this.dataChannel.readyState === "open") {
                    this.dataChannel.send(JSON.stringify({
                        state: this.localState
                    }))
                }
            }, 500)
        }
        if (!this.controlIntervalID) {
            this.controlIntervalID = window.setInterval(() => {
                if (this.dataChannel && this.dataChannel.readyState === "open") {
                    this.dataChannel.send(JSON.stringify({
                        control: this.controlStatus
                    }))
                }
            }, 20)
        }
    }

    stopStateSync = () => {
        clearInterval(this.stateIntervalID)
        clearInterval(this.controlIntervalID)
    }

    syncTrackPC = async (stream: MediaStream, kind: "audio" | "video") => {
        logger.debug("syncinc " + kind + " to pc with stream: " + stream)
        if (stream.active) {
            this.addStreamToPC(stream, kind)
        } else {
            await this.removeStreamFromPC(kind)
        }
    }

    addStreamToPC = (stream: MediaStream, kind: "audio" | "video") => {
        if (this.pc) {
            this.senders[kind] = this.pc.addTrack(stream.getTracks()[0], stream)
        }
    }

    removeStreamFromPC = async (kind: "audio" | "video") => {
        if (this.pc && this.pc.signalingState !== "closed") {
            if (this.senders[kind]) {
                this.pc.removeTrack(this.senders[kind])
            }
        }
    }

    addDataChannel = async () => {
        logger.debug("adding data channel")
        try {
            this.dataChannel = this.pc?.createDataChannel("state_sync")
            if (config.isAndroid) {
                const { gotRemoteControl } = await import('services/reactToAndroid')
                let lastControl = {}
                if (this.dataChannel) {
                    this.dataChannel.onmessage = ({ data }) => {
                        const dataObj = JSON.parse(data)
                        const { state, control } = dataObj
                        if (state) {
                            gotRemoteState(state)
                            this.onRemotestate(state)
                        }
                        if (control && !isEqual(control, lastControl)) gotRemoteControl(control)
                    }
                }
            } else {
                if (this.dataChannel) {
                    this.dataChannel.onmessage = ({ data }) => {
                        const dataObj = JSON.parse(data)
                        const { state } = dataObj
                        if (state) {
                            gotRemoteState(state)
                            this.onRemotestate(state)
                        }
                    }
                }
            }
        } catch (error) {
            logger.error(error.message)
        }
    }

    close = async () => {
        // stop subsribing to control state changes
        signalling.unSubscribe(this.remoteUID, this.sessionID)
        if (this.subscriber) this.subscriber()
        // stop subscribing to video and audio
        unSubscribe(this.remoteUID)
        // inform components that remote audio and video is null

        // stop sending state
        this.stopStateSync()
        // unsubscribe from signalling

        this.callLogging.stop()
        // close the PC
        if (this.pc) {
            this.pc.close();
        }
        setRemoteAudio(new MediaStream())
        setRemoteVideo(new MediaStream())
        if (this.pc) {
            delete this.pc
        }
    }
}

export default Webrtc