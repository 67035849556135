import { createSlice } from '@reduxjs/toolkit'
import { createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import { isEqual } from 'lodash'

import { getOwnAudioStream, disableOwnAudioStream } from '../../services/streams'

import store, { storeInjectReducer, createSubscriber } from '../store'
import { useSelector } from 'react-redux'

const storeKey = 'incall'

export interface IState {
    spinConnected: boolean;
    avatarMode: string |  null;
    handRaised: boolean;
    requestingRaisedHand: boolean;
    driveMode: string;
    spinID: null | string;
    isMicMute: boolean;
    userType: null | string;
}

interface IStateContainer {
    local: IState
    remote: IState
}

interface ISelectorState {
    [key: string]: IStateContainer
}

const initialState = {
    local: {
        spinConnected: false,
        avatarMode: null,
        handRaised: false,
        requestingRaisedHand: false,
        driveMode: "UNSAFE",
        spinID: null,
        isMicMute: false,
        userType: null,
    },
    remote: {
        spinConnected: false,
        avatarMode: null,
        handRaised: null,
        requestingRaisedHand: null,
        driveMode: "UNSAFE",
        spinID: null,
        isMicMute: null,
        userType: null,
    }
}

const toggleMicrophone = createAsyncThunk(
    storeKey + "/setIsMicMute",
    async () => {
        if (selectLocal(store.getState()).isMicMute) {
            await getOwnAudioStream()
            return false
        } else {
            disableOwnAudioStream()
            return true
        }
    }
)


const inCallSlice = createSlice({
    name: storeKey,
    initialState,
    reducers: {
        setRemoteState: (state, action) => { state.remote = action.payload },
        toggleRequestRaiseHand: (state) => { state.local.requestingRaisedHand = state.local.requestingRaisedHand ? false : true },
        toggleLocalDriveMode: (state) => {
            state.local.driveMode = state.local.driveMode === "SAFE" ? "UNSAFE" : "SAFE"
            console.log("toggling to", state.local.driveMode)
        },
        setIsMicMute: (state, action) => { state.local.isMicMute = action.payload }
    },
    extraReducers: {
        [toggleMicrophone.fulfilled.type]: (state, action) => { state.local.isMicMute = action.payload },
        'android/setSpinConnectionStatus': (state, action) => { state.local.spinConnected = action.payload },
        'android/setID': (state, action) => { state.local.spinID = action.payload },
        'firebase/userLoggedIn': (state, action) => { state.local.userType = action.payload.type },
        'avatar/setAvatar': (state, action) => { state.local.avatarMode = action.payload },
        'webrtc/reset': (state) => {
            state.remote = initialState.remote
            state.local.avatarMode = initialState.local.avatarMode
            state.local.driveMode = initialState.local.driveMode
            state.local.handRaised = initialState.local.handRaised
            state.local.isMicMute = initialState.local.isMicMute
            state.local.requestingRaisedHand = initialState.local.requestingRaisedHand
        }
    }
})

export const { actions, reducer } = inCallSlice

storeInjectReducer(storeKey, reducer)


// ------------------------------- SELECTORS ------------------------------------ //


const selectLocal = (state: ISelectorState) => state[storeKey].local
const selectRemote = (state: ISelectorState) => state[storeKey].remote

const getRemoteAvatar = createSelector(selectRemote, remote => remote.avatarMode)
export const useRemoteAvatar = () => useSelector(getRemoteAvatar)
const getRemoteHandRasiedRequest = createSelector(selectRemote, remote => remote.requestingRaisedHand)
export const useRemoteHandRaisedRequest = () => useSelector(getRemoteHandRasiedRequest)
const getRemoteSpinConnected = createSelector(selectRemote, remote => remote.spinConnected)
export const useRemoteSpinConnected = () => useSelector(getRemoteSpinConnected)

const getLocalAvatar = createSelector(selectLocal, local => local.avatarMode)
export const useLocalAvatar = () => useSelector(getLocalAvatar)
const getLocalHandRaised = createSelector(selectLocal, local => local.requestingRaisedHand)
export const useLocalHandRaised = () => useSelector(getLocalHandRaised)
const getSpinID = createSelector(selectLocal, local => local.spinID)
export const useSpinID = () => useSelector(getSpinID)

const getIsMicMute = createSelector(selectLocal, local => local.isMicMute)
export const useIsMicMute = () => useSelector(getIsMicMute)

export const getLocalDriveMode = createSelector(selectLocal, local => local.driveMode)
export const getRemoteDriveMode = createSelector(selectRemote, remote => {
    if (remote.spinConnected) {
        return remote.driveMode
    } else {
        return false
    }
})

const getRemoteUSerType = createSelector(selectRemote, remote => remote.userType)
export const useRemoteUserType = () => useSelector(getRemoteUSerType)


// ------------------------ ACTIONS ----------------------- ///


export const setIsMicMute = async (state: boolean) => {
    if (!state) {
        await getOwnAudioStream()
        store.dispatch(actions.setIsMicMute(false))
    } else {
        disableOwnAudioStream()
        store.dispatch(actions.setIsMicMute(true))
    }
}
//@ts-ignore
export const toggleMicMute = () => store.dispatch(toggleMicrophone())
export const toggleRequestRaiseHand = () => store.dispatch(actions.toggleRequestRaiseHand())
export const toggleLocalDriveMode = () => store.dispatch(actions.toggleLocalDriveMode())

export const gotRemoteState = (state: IStateContainer) => {
    if (!isEqual(store.getState()[storeKey].remote, state)) {
        //if(state && state.avatarMode !== "VIDEO") setRemoteVideo(null) 
        store.dispatch(actions.setRemoteState(state))
    }
}


// ------------------------------- UTILS --------------------------- //

export const localSubscribe = createSubscriber(storeKey, 'local')
