import config from '../config/config'
import { gotUserData, gotPublicUserData, userLoggedIn, getUID, updateContactStatus, userLoggedOut } from '../store/slices/firebaseSlice'
import { signalling } from '../store/slices/webrtcSlice'
import {
    editContactName as androidEditContactName,
    startUpdatingContactsStatus as androidstartUpdatingContactsStatus,
    stopUpdatingContactsStatus as androidstopUpdatingContactsStatus,
    loginUser as androidLoginUser,
    setLanguage as androidSetLanguage,
    resetPincode as androidResetPincode,
    editDisplayName as androidEditDisplayName,
    sendResetPassword as androidSendResetPassword,
    registerRobot as androidRegisterRobot,
    signInWithCustomToken as androidSignInWithCutomToken,
    setReversSpinControl as androidSetReversSpinControl,
    getICE as androidGetICE,
    sendSignal as fbSendSignal,
    logData as fbLogData,
    logError as fbLogError
} from './reactToAndroid'

let signallingCallbacks = []

export const onSignalling = (callback) => {
    if (config.isWeb) {
        signallingCallbacks.push(callback)
    }
}

let con;
let ownSessionID;

let databaseRefs = [];
let listeners = [];

export const setupListeners = async () => {
    const { auth, database, firestore, firebase } = await import('./webFirebase')
    auth.onAuthStateChanged(async (user) => {
        if (user) {
            // clear previous listerners and updaters
            detachListeners()
            stopUserStatusUpdater()
            con = await database.ref('userStatus/' + user.uid).push();
            ownSessionID = con.key
            startUserStatusUpdater()
            // User presence system
            const onlineStatusRef = database.ref('.info/connected');
            //onlineStatusRef.off()
            databaseRefs.push(onlineStatusRef);
            onlineStatusRef.on('value', (snapshot) => {
                if (snapshot.val() === true) {
                    con.onDisconnect().set({
                        time: 0,
                        type: "web"
                    });
                    con.set({
                        time: firebase.database.ServerValue.TIMESTAMP,
                        type: "web"
                    })
                }
            });

            // listen to changes in user data
            listeners.push(firestore.collection('users').doc(user.uid).onSnapshot(doc => {
                gotUserData((doc.data() || {}))
            }))

            // listen to changes in public user data
            listeners.push(firestore.collection('public').doc(user.uid).onSnapshot(doc => {
                gotPublicUserData((doc.data() || {}))
            }))

            // start listening to your signalling channel
            // the realtime database
            const sigRef = database.ref('signalling/' + user.uid);
            databaseRefs.push(sigRef);
            sigRef.on('child_added', async (snapshot) => {
                if (snapshot.exists()) {
                    const value = snapshot.val()
                    const { uid, webrtcSessionID, sessionID } = value
                    if (uid === auth.currentUser.uid && sessionID === ownSessionID) {
                        return
                    }
                    if ((value.description || value.candidate)) {
                        signallingCallbacks.forEach(callback => {
                            callback(uid, webrtcSessionID, value)
                        })
                    } else {
                        signalling(value)
                    }
                }
            });
            const type = (await user.getIdTokenResult()).claims.type
            userLoggedIn({
                displayName: user.displayName,
                email: user.email,
                photoUrl: user.photoURL,
                providerId: user.providerId,
                uid: user.uid,
                isEmailVerified: user.emailVerified,
                type: type
            })
        } else {
            detachListeners()
            stopUserStatusUpdater()
            userLoggedOut()
        }
    })
}


export async function setTrustedCaller(contactID, status) {
    if (config.isWeb) {
        const { firestore, auth } = await import('services/webFirebase')
        const contactIndex = "contacts." + contactID + ".trusted";
        firestore.collection('users').doc(auth.currentUser.uid).update(contactIndex, status)
    }
    if (config.isAndroid) {
        const Android = await import('./reactToAndroid')
        Android.setTrustedCaller(contactID, status)
    }
}

const detachListeners = () => {
    databaseRefs.forEach((ref,) => {
        ref.off();
    });
    databaseRefs = [];
    listeners.forEach(listener => {
        listener();
    });
    listeners = [];
}

let userStatusUpdaterID
const stopUserStatusUpdater = () => {
    clearInterval(userStatusUpdaterID);
}

const startUserStatusUpdater = async () => {
    stopUserStatusUpdater()
    const { firebase } = await import('./webFirebase')
    con.set({
        time: firebase.database.ServerValue.TIMESTAMP,
        type: "web"
    })
    userStatusUpdaterID = window.setInterval(() => {
        con.set({
            time: firebase.database.ServerValue.TIMESTAMP,
            type: "web"
        })
    }, 30000)
}

//Helper only used by web
const signallingSend = async (data, remoteUID, webrtcSessionID) => {
    if (!data) {
        return
    }
    const { database, auth } = await import('./webFirebase')
    const toSend = {
        ...data,
        uid: auth.currentUser.uid,
        sessionID: ownSessionID,
        webrtcSessionID: webrtcSessionID
    }
    console.log("sending signal: " + JSON.stringify(toSend))
    const ref = database.ref('signalling/' + remoteUID).push()
    await ref.set(toSend).then(() => {
        ref.remove()
    })
}

// API
export const editDisplayName = async (displayName) => {
    if (config.isAndroid) {
        return await androidEditDisplayName(displayName)
    }
    if (config.isWeb) {
        const { auth, firestore } = await import('./webFirebase')
        auth.currentUser.updateProfile({
            displayName: displayName
        })
        try {
            await firestore.collection('public').doc(auth.currentUser.uid).update({
                displayName: displayName
            })
            return { type: "success" }
        } catch (error) {
            console.log(error.code)
            try {
                await firestore.collection('public').doc(auth.currentUser.uid).set({
                    displayName: displayName
                })
                return { type: "success" }
            } catch (error2) {
                return { type: "error" }
            }
        }
    }
}

export const setReverseSpinControl = async(state) => {
    if (config.isAndroid) {
        return await androidSetReversSpinControl(state)
    }
    if (config.isWeb) {
        const { auth, firestore } = await import('./webFirebase')
        try {
            await firestore.collection('robots').doc(auth.currentUser.uid).update({
                reverseSpinControl: state
            })
            return { type: "success" }
        } catch (error) {
            console.log(error.code)
            try {
                await firestore.collection('robots').doc(auth.currentUser.uid).set({
                    reverseSpinControl: state
                })
                return { type: "success" }
            } catch (error2) {
                return { type: "error" }
            }
        }
    }
}

export const logOut = async () => {
    if (config.isAndroid) {
        window.Android.logOut()
    }
    if (config.isWeb) {
        const { auth } = await import('services/webFirebase')
        await auth.signOut()
    }
}

export const logError = async (msg, stack) => {
    if(process.env.NODE_ENV === "development") return
    try {
        const errorObj = {
            error: msg,
            stack: stack,
            appVersion: navigator.appVersion,
            platform: navigator.platform
        }
        if (config.isAndroid) {
            fbLogData(msg, stack)
            fbLogError(errorObj)
        }
        if (config.isWeb) {
            const { firestore, firebase, auth, functions } = await import('services/webFirebase')
            if (msg) {
                functions.httpsCallable("logError")(errorObj)
                firestore.collection("logs").doc((auth.currentUser && auth.currentUser.uid) || "not_logged_in").collection("errors").add({
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    error: msg,
                    componentStack: stack,
                    appCodeName: navigator.appCodeName,
                    appName: navigator.appName,
                    appVersion: navigator.appVersion,
                    cookieEnabled: navigator.cookieEnabled,
                    language: navigator.language,
                    platform: navigator.platform,
                    userAgent: navigator.userAgent
                })
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const getICE = async () => {
    try {
        let response
        if (config.isAndroid) {
            response = await androidGetICE()
        }
        if (config.isWeb) {
            const { functions } = await import('./webFirebase')
            response = await functions.httpsCallable('getICeServers')()
            response = response.data
        }
        if (response.type === "error") {
            return false
        }
        response = JSON.parse(response.data.config)
        const servers = response.v;
        return servers.iceServers;
    } catch (error) {
        console.log(error.message)
        return false
    }
}

export const sendCandidate = (candidate, remoteUID, webrtcSessionID) => {
    if (config.isAndroid) {
        window.Android.sendCandidate(JSON.stringify(candidate), remoteUID, webrtcSessionID)
    }
    if (config.isWeb) {
        signallingSend({ candidate: JSON.stringify(candidate) }, remoteUID, webrtcSessionID)
    }
}

export const sendDescription = (description, remoteUID, webrtcSessionID) => {
    console.log("NEW FIREBASE: sending sdp: " + description.type)
    if (config.isAndroid) {
        window.Android.sendSDP(JSON.stringify(description), remoteUID, webrtcSessionID)
    }
    if (config.isWeb) {
        signallingSend({ description: JSON.stringify(description) }, remoteUID, webrtcSessionID)
    }
}

export const localHangUp = (remoteUID, webrtcSessionID) => {
    if (config.isAndroid) {
        fbSendSignal("hangup", remoteUID, webrtcSessionID)
    }
    if (config.isWeb) {
        signallingSend({ msg: "hangup" }, remoteUID, webrtcSessionID)
    }
}

export const remoteHangup = () => {
    if (config.isAndroid) {
        window.Android.closeNotification()
    }
}

export const incommingCall = () => {
    if (config.isAndroid) {
        window.Android.incomingCall()
    }
}

export const localRejectCall = (remoteUID, webrtcSessionID) => {
    if (config.isAndroid) {
        if (remoteUID) fbSendSignal("rejected", remoteUID, webrtcSessionID)
        window.Android.closeNotification()
    }
    if (config.isWeb) {
        if (remoteUID) signallingSend({ msg: "rejected" }, remoteUID, webrtcSessionID)
    }
}

export const localAcceptCall = (remoteUID, webrtcSessionID) => {
    if (config.isAndroid) {
        window.Android.closeNotification()
        fbSendSignal("accepted", remoteUID, webrtcSessionID)
    }
    if (config.isWeb) {
        signallingSend({ msg: "accepted" }, remoteUID, webrtcSessionID)
    }
}

export const placeCall = (remoteUID, webrtcSessionID) => {
    if (config.isAndroid) {
        fbSendSignal("connect", remoteUID, webrtcSessionID)
    }
    if (config.isWeb) {
        signallingSend({ msg: "connect" }, remoteUID, webrtcSessionID)
    }
}

export const sendSignalling = (msg, remoteUID, webrtcSessionID) => {
    if (config.isAndroid) {
        fbSendSignal(msg, remoteUID, webrtcSessionID)
    }
    if (config.isWeb) {
        signallingSend({ msg: msg }, remoteUID, webrtcSessionID)
    }
}


export const stopUpdatingContactsStatus = async () => {
    if (config.isWeb) {
        contactStatusRefs.forEach((ref,) => {
            ref.off();
        });
        contactStatusRefs = []
    }
    if (config.isAndroid) {
        androidstopUpdatingContactsStatus()
    }
}

let contactStatusRefs = []
export const startUpdatingContactsStatus = async (contactUIDs) => {
    if (config.isWeb) {
        await stopUpdatingContactsStatus()
        const { database, firebase } = await import('./webFirebase')
        const uid_local = getUID()
        contactUIDs.forEach(contactUID => {
            const ref = database.ref('userStatus/' + contactUID);
            contactStatusRefs.push(ref);
            ref.on('value', snap => {
                if (snap && snap.val()) {
                    const value = snap.val();
                    const now = firebase.firestore.Timestamp.now().seconds;
                    let connections = [];
                    for (let val of Object.values(value)) {
                        if (now * 1000 - val.time < 60000) {
                            connections.push(true)
                        }
                    }
                    if ((uid_local !== contactUID && connections.length > 0) || (uid_local === contactUID && connections.length > 1)) {
                        // contact is online
                        updateContactStatus({ uid: contactUID, status: true })
                    } else {
                        // contact is offline
                        updateContactStatus({ uid: contactUID, status: false })
                    }
                } else {
                    // contact is offline
                    updateContactStatus({ uid: contactUID, status: false })
                }
            });
        })
    }
    if (config.isAndroid) {
        androidstartUpdatingContactsStatus()
    }
}

// should only be imported by pro

export const registerRobot = async (robotID, licenseKey, serialID, language, pin) => {
    if (config.isAndroid) {
        return await androidRegisterRobot({
            robotID: robotID,
            licenseKey: licenseKey,
            serialID: serialID,
            pin: pin,
            language: language
        })
    }
}

export const loginUser = async (username, password) => {
    if (config.isAndroid) {
        const resAndroid = await androidLoginUser({ username, password })
        if (resAndroid.type === "success" && resAndroid.data && resAndroid.data.token) {
            await androidSignInWithCutomToken(resAndroid.data.token)
        }
        return resAndroid
    }
    if (config.isWeb) {
        const { functions, auth } = await import('./webFirebase')
        const res = await functions.httpsCallable("signIn")({ username, password })
        if (res.data && res.data.type === "success" && res.data.data.token)
            auth.signInWithCustomToken(res.data.data.token)
        return res.data
    }
}

export const editContactName = async (name, id) => {
    if (config.isAndroid) {
        return await androidEditContactName({ contactID: id, newName: name })
    }
    if (config.isWeb) {
        const { functions } = await import('./webFirebase')
        const res = await functions.httpsCallable("editContactName")({ contactID: id, newName: name })
        return res.data
    }
}

export const setLanguage = async (language) => {
    if (config.isAndroid) {
        await androidSetLanguage({ language })
    }
    if (config.isWeb) {
        const { functions } = await import('./webFirebase')
        await functions.httpsCallable("setLanguage")({ language: language })
    }
}


export const resetPincode = async (robotID, serialID, licenseKey, pin) => {
    if (config.isAndroid) {
        const resAndroid = await androidResetPincode(
            {
                robotID,
                serialID,
                licenseKey,
                pin
            })
        if (resAndroid.type === "success" && resAndroid.data && resAndroid.data.token) {
            await androidSignInWithCutomToken(resAndroid.data.token)
        }
        return resAndroid
    }
}

export const sendResetPassword = async (uid, userType) => {
    if (config.isAndroid) {
        return await androidSendResetPassword(uid, userType)
    }
}