import config from 'config/config'
import store from 'store/store'
import { reverseSpinControlSubscribe } from 'store/slices/firebaseSlice'


interface wrapperReturn {
    msg?: string | null;
    type?: string | null;
    code?: string | null;
    data?: { token?: string, email?: string } | null;
}

const wrapper = (funcString: string) => async (...args: any[]): Promise<wrapperReturn> => {
    let timeoutid
    // set a unique id
    let resolveid: string = "android" + String(Math.round(Math.random() * 1000000000))
    while (Object.keys(window).includes(resolveid)) {
        resolveid = "android" + String(Math.round(Math.random() * 1000000000))
    }
    const res = await new Promise<wrapperReturn>(resolve => {
        (window as any)[resolveid] = (type = null, code = null, msg = null, data = null) => {
            const obj = {
                type: type,
                code: code,
                msg: msg,
                data: data
            }
            resolve(obj)
        }
        (window as any).Android[funcString](resolveid, ...args)
        timeoutid = window.setTimeout(() => { resolve({ type: "error", msg: "timeout" }); console.log(funcString + " timeout"); }, 15000)
    })
    clearTimeout(timeoutid)
    delete (window as any)[resolveid]
    return res
}

interface setupReturn {
    msg?: string | null;
    type?: string | null;
    code?: string | null;
    data?: { token?: string, email?: string } | null;
}

export const setupAndroid = async () => {
    let timeoutid
    const res = await new Promise<setupReturn>(resolve => {
        (window as any)["setupandroidid"] = (type = null, code = null, msg = null) => {
            const obj = {
                type: type,
                code: code,
                msg: msg
            }
            resolve(obj)
        }
        (window as any).Android.setupAndroid()
        timeoutid = window.setTimeout(() => { resolve({ type: "error", msg: "timeout" }); console.log("android timeout"); }, 10000)
    })
    clearTimeout(timeoutid)
    delete (window as any)["setupandroidid"]
    return res
}

interface firebaseFunctionReturn {
    msg?: string | null;
    type?: "error" | "success" | null;
    code?: string | null;
    data?: { token?: string, email?: string } | null;
}

export const getCallable = (name: string) => async (inputdata: object | undefined): Promise<firebaseFunctionReturn> => {
    let timeoutid
    // set a unique id
    let resolveid = "android" + String(Math.round(Math.random() * 1000000000))
    while (Object.keys(window).includes(resolveid)) {
        resolveid = "android" + String(Math.round(Math.random() * 1000000000))
    }
    const res: firebaseFunctionReturn = await new Promise(resolve => {
        (window as any)[resolveid] = (type = null, code = null, msg = null, data = null) => {
            const obj = {
                type: type,
                code: code,
                msg: msg,
                data: data
            }
            resolve(obj)
        }
        (window as any).Android.firebaseFunction(resolveid, name, JSON.stringify(inputdata))
        timeoutid = window.setTimeout(() => { resolve({ type: "error", msg: "timeout" }); console.log("android timeout"); }, 15000)
    })
    clearTimeout(timeoutid)
    delete (window as any)[resolveid]
    return res
}


// not Firebase
export const showUI = wrapper("showUI")
export const hideUI = wrapper("hideUI")
export const getSerial = wrapper("getSerial")
export const goToStore = () => (window as any).Android.goToStore()
export const sendSignal = wrapper("signallingSend")
export const logData = wrapper("logData")
export const copyToClipboard = wrapper("copyToClipboard")
export const getBatteryLevel = wrapper("getBatteryLevel")

// CONVERTED FIREBASE 
export const signInWithCustomToken = wrapper("signInWithCustomToken")

// FIREBASE FUNCTIONS
export const loginUser = getCallable("signIn") // wrapper("signInUser")
export const getICE = getCallable("getICeServers")
export const createUser = getCallable("createUser")
export const deleteUser = getCallable("deleteUser")
export const editContactName = getCallable("editContactName")
export const resetPassword = getCallable("resetPassword")
export const resetPincode = getCallable("resetPincode")
export const sendResetPassword = getCallable("sendResetPassword")
export const signInRobot = getCallable("signInRobot")
export const setLanguage = getCallable("setLanguage")
export const registerRobot = getCallable("registerRobot")
export const logError = getCallable("logError")

// TO BE CONVERTED
export const startUpdatingContactsStatus = wrapper("startUpdatingContactsStatus")
export const stopUpdatingContactsStatus = wrapper("stopUpdatingContactsStatus")

export const setReversSpinControl = wrapper("setReverseSpinControl")
export const editDisplayName = wrapper("editDisplayName")
export const getCurrentVersion = wrapper("getCurrentVersion")
export const getLanguage = wrapper("getLanguage")
export const showToast = async(text: string, long = false) => wrapper("showToast")(text, long)
export const setTrustedCaller = wrapper("setTrustedCaller")

let reverseSpinControl = false

reverseSpinControlSubscribe((state: boolean) => {
    reverseSpinControl = state
})

export const gotRemoteControl = (control: any) => {
    if (config.isAndroid) {
        if (control) {
            if ((store.getState() as any).incall.local.driveMode === "SAFE") {
                (window as any).Android.updateState(control.x, 0, reverseSpinControl)
            } else {
                (window as any).Android.updateState(control.x, control.y, reverseSpinControl)
            }
        }
    }
}