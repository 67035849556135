import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { CombinedState, combineReducers, Reducer } from 'redux'
import { useState, useLayoutEffect } from 'react'
import { responsiveStateReducer, responsiveStoreEnhancer } from 'redux-responsive'

//@ts-ignore
const logger = store2 => next => action => {
    //console.log('dispatching', JSON.stringify(action))
    let result = next(action)

    return result
}

let reducers: { [key: string]: Reducer } = { browser: responsiveStateReducer };

const store = configureStore({
    reducer: combineReducers(reducers),
    middleware: [...getDefaultMiddleware(), logger],
    enhancers: [responsiveStoreEnhancer],

})

export type AppDispatch = typeof store.dispatch;

export function storeInjectReducer<T>(key: string, reducer: Reducer<T>): void {
    reducers[key] = reducer;
    store.replaceReducer(combineReducers(reducers));
}

export const createSubscriber = (...keys: string[]) => {
    const getState = () => {
        let state = store.getState()
        keys.forEach(key => {
            state = state[key]
        })
        return state
    }
    return (f: any) => {
        let lastState = getState();
        f(lastState)
        return store.subscribe(
            () => lastState !== getState() && f((lastState = getState()))
        )
    }
}

export const createHook = (...keys: string[]) => {
    const getState = () => {
        let state = store.getState()
        keys.forEach(key => {
            state = state[key]
        })
        return state
    }
    const subscribe = function callbackFunction<T>(state: CombinedState<T>): () => void {
        let lastState = getState();
        return store.subscribe(
            () => lastState !== getState() && callbackFunction((lastState = getState()))
        );
    };
    return () => {
        const [state, setState] = useState(getState());
        useLayoutEffect(() => subscribe(setState), [setState]);
        return state;
    }
}

export function createSelectorHook(selector: (state: CombinedState<any>) => string) {
    const getState = () => {
        return selector(store.getState())
    }
    const subscribe = function callbackFunction<T>(state: CombinedState<T>): () => void {
        let lastState = getState();
        return store.subscribe(
            () => lastState !== getState() && callbackFunction((lastState = getState()))
        );
    };
    return () => {
        const [state, setState] = useState(getState());
        useLayoutEffect(() => subscribe(setState), [setState]);
        return state;
    }
}


export const useBrowser = createHook("browser")

export default store