import { createSlice, createSelector } from '@reduxjs/toolkit'
import store, { storeInjectReducer } from '../store'
import { useSelector } from 'react-redux'

import { getOwnVideoStream, diableOwnVideo } from '../../services/streams'

import { getUserType } from 'store/slices/firebaseSlice'

const storeKey = 'avatar'

interface IState {
    avatar: string | null,
    futureAvatar: null | string,
    loading: boolean,

}

interface ISelectorState {
    [key: string]: IState
}

const initialState = {
    avatar: null,
    futureAvatar: null,
    loading: false
}

const avatarSlice = createSlice({
    name: storeKey,
    initialState,
    reducers: {
        setAvatar(state, action) {
            state.avatar = action.payload
            state.loading = false
        },
        setFutureAvatar(state, action) {
            state.futureAvatar = action.payload
        },
        setLoading(state, action) { state.loading = action.payload }
    }
})

const { actions, reducer } = avatarSlice

storeInjectReducer(storeKey, reducer)

const selectAvatar = (state: ISelectorState) => state[storeKey].avatar
const selectLoading = (state: ISelectorState) => state[storeKey].loading
const selectFutureAvatar = (state: ISelectorState) => state[storeKey].futureAvatar

const getLoading = createSelector(selectLoading, loading => loading)
const getAvatar = createSelector(selectAvatar, avatar => avatar)
const getFutureAvatar = createSelector(selectFutureAvatar, futureAvatar => futureAvatar)

export const useLoading = () => useSelector(getLoading)
export const useAvatar = () => useSelector(getAvatar)
export const useFutureAvatar = () => useSelector(getFutureAvatar)

export const setAvatar = async (avatar: null | string) => {
    store.dispatch(actions.setFutureAvatar(avatar))
    store.dispatch(actions.setLoading(true))
    if (avatar === "VIDEO") {
        if (getUserType(store.getState()) === "robot") {
            await getOwnVideoStream(800, "user")
        } else {
            await getOwnVideoStream(480, "user")
        }
    } else {
        diableOwnVideo()
    }
    store.dispatch(actions.setAvatar(avatar))
}



