import FirebaseFunction from './firebaseFunction'
import { nanoid } from 'nanoid'

export interface ICallLogging {
    start(remoteID: string): any;
    avatarModeChanged(avatarMode: string): any;
    handRaised(): any;
    handLowered(): any;
    stop(): any;
    gotConnected(): any;
    remoteMicMuted(): any;
    remoteMicUnMuted(): any;
    ownMicMuted(): any;
    ownMicUnMuted(): any;
}

const actionTypes = {
    started: "STARTED",
    continued: "CONTINUED",
    ended: "ENDED",
    avatarChanged: "AVATAR_CHANGED",
    handRaised: "HAND_RAISED",
    handLowered: "HAND_LOWERED",
    remoteMicIsMute: "MIC_IS_MUTE",
    remoteMicIsUnMute: "MIC_IS_UNMUTE",
    ownMicIsMute: "OWN_MIC_IS_MUTE",
    ownMicIsUnMute: "OWN_MIC_IS_UNMUTE",
    gotConnected: "GOT_CONNECTED"
}

class CallLogging implements ICallLogging {
    private remoteId: string;
    private logId;
    private intervalId = 0;
    private updatePeriod = 10*60*1000;
    private running: boolean;
    sendUpdate: FirebaseFunction | any;

    constructor(remoteId: string) {
        this.remoteId = remoteId;
        this.logId = nanoid();
        this.sendUpdate = new FirebaseFunction("callLogging");
        this.running = false;
    }

    start() {
        if(!this.running) {
            this.running = true;
            this.sendUpdate.call({
                actionType: actionTypes.started,
                logId: this.logId,
                remoteID: this.remoteId
            })
            this.intervalId = window.setInterval(() => {
                this.update()
            }, this.updatePeriod)
        }
    }

    private update() {
        this.sendUpdate.call({
            actionType: actionTypes.continued,
            logId: this.logId
        })
    }

    gotConnected() {
        this.sendUpdate.call({
            actionType: actionTypes.gotConnected,
            logId: this.logId
        })
    }

    avatarModeChanged(avatarMode: string) {
        this.sendUpdate.call({
            actionType: actionTypes.avatarChanged,
            avatarMode: avatarMode,
            logId: this.logId
        })
    }

    handRaised() {
        this.sendUpdate.call({
            actionType: actionTypes.handRaised,
            logId: this.logId
        })
    }

    handLowered() {
        this.sendUpdate.call({
            actionType: actionTypes.handLowered,
            logId: this.logId
        })
    }

    remoteMicMuted() {
        this.sendUpdate.call({
            actionType: actionTypes.remoteMicIsMute,
            logId: this.logId
        })
    }

    remoteMicUnMuted() {
        this.sendUpdate.call({
            actionType: actionTypes.remoteMicIsUnMute,
            logId: this.logId
        })
    }

    ownMicMuted() {
        this.sendUpdate.call({
            actionType: actionTypes.ownMicIsMute,
            logId: this.logId
        })
    }

    ownMicUnMuted() {
        this.sendUpdate.call({
            actionType: actionTypes.ownMicIsUnMute,
            logId: this.logId
        })
    }

    stop() {
        clearInterval(this.intervalId);
        this.sendUpdate.call({
            actionType: actionTypes.ended,
            logId: this.logId
        })
        this.running = false;
    }
}

export default CallLogging

export class DummyCallLoggin implements ICallLogging {
    start() {}
    gotConnected() {}
    avatarModeChanged(){}
    handRaised() {}
    handLowered() {}
    remoteMicMuted() {}
    remoteMicUnMuted() {}
    ownMicMuted() {}
    ownMicUnMuted() {}
    stop() {}
}