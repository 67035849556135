import { sendCandidate, sendDescription as sd, getICE as tgetICE } from '../services/newFirebase'

class Signalling {
    constructor() {
        this.pcs = {}
    }

    setOnSignal = (f) => {
        f(this.dispatchSignalling)
    }

    subscribe = (remoteUID, sessionID, onCandidate, onDescription) => {
        this.pcs[remoteUID+sessionID] = {
            onCandidate: onCandidate,
            onDescription: onDescription
        }
    }

    dispatchSignalling = (remoteUID, sessionID, data) => {
        const { candidate, description } = data
        if (this.pcs[remoteUID+sessionID]) {
            if (description) {
                this.pcs[remoteUID+sessionID].onDescription(description)
            } else if (candidate) {
                this.pcs[remoteUID+sessionID].onCandidate(candidate)
            }
        }
    }

    unSubscribe = (remoteUID, sessionID) => {
        if (this.pcs[remoteUID+sessionID]) {
            delete this.pcs[remoteUID+sessionID]
        }
    }

    sendCandidate = (candidate, remoteUID, webrtcSessionID) => {
        sendCandidate(candidate, remoteUID, webrtcSessionID)
    }

    sendDescription = (description, remoteUID, webrtcSessionID) => {
        console.log("sending sdp")
        sd(description, remoteUID, webrtcSessionID)
    }

    getICE = () => tgetICE()
}

const signalling = new Signalling()

export default signalling