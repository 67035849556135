import { createSlice } from '@reduxjs/toolkit'

import store, { storeInjectReducer } from '../store'
import { useSelector } from 'react-redux'

const storeKey = 'showables'

interface IState {
    drawer: boolean,
    changeLanguage: boolean,
    logOut: boolean,
    loading: boolean,
    newUserCreated: boolean
}

interface ISelectorState {
    [key: string]: IState
}
const initialState = {
    drawer: false,
    changeLanguage: false,
    logOut: false,
    loading: false,
    newUserCreated: false
}


const ShowablesSlice = createSlice({
    name: storeKey,
    initialState,
    reducers: {
        setDrawer(state, action) { state.drawer = action.payload },
        setChangeLanguage(state, action) { state.changeLanguage = action.payload },
        setLogOut(state, action) { state.logOut = action.payload },
        setLoading(state, action) { state.loading = action.payload },
        setNewUserCreated(state, action) { state.newUserCreated = action.payload }
    }
})

export const { actions, reducer } = ShowablesSlice

storeInjectReducer(storeKey, reducer)

// selectors 
const getDrawer = (state: ISelectorState) => state[storeKey].drawer
export const useDrawer = () => useSelector(getDrawer)
const getChangeLanguage = (state: ISelectorState) => state[storeKey].changeLanguage
export const useChangeLanguage = () => useSelector(getChangeLanguage)
const getLogOut = (state: ISelectorState) => state[storeKey].logOut
export const useLogOut = () => useSelector(getLogOut)
const getLoading = (state: ISelectorState) => state[storeKey].loading
export const useLoading = () => useSelector(getLoading)
const getNewUserCreated = (state: ISelectorState) => state[storeKey].newUserCreated
export const useNewUserCreated = () => useSelector(getNewUserCreated)

// dispatches
export const setDrawer = (state: boolean) => store.dispatch(actions.setDrawer(state))
export const setChangeLanguage = (state: boolean) => store.dispatch(actions.setChangeLanguage(state))
export const setLogOut = (state: boolean) => store.dispatch(actions.setLogOut(state))
export const setLoading = (state: boolean) => store.dispatch(actions.setLoading(state))
export const setNewUserCreated = (state: boolean) => store.dispatch(actions.setNewUserCreated(state))

