import { createSlice, createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import store, { storeInjectReducer } from '../store'

import { actions as firebaseActions } from './firebaseSlice'

import da from '../../languages/da'
import en from '../../languages/en'

import { LanguageStrings } from 'languages/LanguageStringTypes'

const storeKey = 'language'

type iLanguage = {
    [key in LanguageStrings]: string
}

// type stringsObject = { [key: iLanguage]: string }

interface IState {
    strings: iLanguage;
    currentLanguage: string,
    availableLanguages: availabelLanguages[];
}
const languagesObj = {
    da: da,
    en: en
}
const initialState = {
    strings: da,
    currentLanguage: "da",
    availableLanguages: Object.keys(languagesObj)
}
interface ISelectorState {
    [key: string]: IState
}
export type availabelLanguages = "da" | "en"

const languageSlice = createSlice({
    name: storeKey,
    initialState: initialState,
    reducers: {
        setLang(state, action) {
            if (state.availableLanguages.includes(action.payload)) {
                state.strings = languagesObj[action.payload as availabelLanguages]
                state.currentLanguage = action.payload
            } else {
                state.strings = languagesObj.en
                state.currentLanguage = "en"
            }
        }
    },
    extraReducers: {
        [firebaseActions.gotUserData.type]: (state, action) => {
            const language = action.payload.language
            if (state.availableLanguages.includes(language)) {
                state.strings = languagesObj[language as availabelLanguages]
                state.currentLanguage = language
            }
        }
    }
})


const selectStrings = (state: ISelectorState) => state[storeKey].strings
const selectCurrentLanguage = (state: ISelectorState) => state[storeKey].currentLanguage
const selectAvailableLanguages = (state: ISelectorState) => state[storeKey].availableLanguages

const getStrings = createSelector(selectStrings, strings => strings)
export const useStrings = () => useSelector(getStrings)
const getCurrentLanguage = createSelector(selectCurrentLanguage, currentLang => currentLang)
export const useCurrentLanguage = () => useSelector(getCurrentLanguage)
const getAvailableLanguage = createSelector(selectAvailableLanguages, availableLang => availableLang)
export const useAvailableLanguage = () => useSelector(getAvailableLanguage)

const getString = (stringName: LanguageStrings) => createSelector(selectStrings, strings => {
    if (strings[stringName]) return strings[stringName]
    return "STRING NOT FOUND"
})

export const useTranslatedString = (key: LanguageStrings) => useSelector(getString(key))

const { actions, reducer } = languageSlice


storeInjectReducer(storeKey, reducer)

export const setLang = (lang: string) => store.dispatch(actions.setLang(lang))
