import { createSlice, createSelector } from '@reduxjs/toolkit'

import store, { storeInjectReducer, createSelectorHook, createSubscriber } from '../store'

import { getRemoteUID } from './webrtcSlice'
import { getSpinID } from 'store/slices/androidSlice'
import { useSelector } from 'react-redux'

import { logOut as fbLogOut, editDisplayName as fbEditDisplayName } from '../../services/newFirebase'


const storeKey = 'firebase'

type ContactsObject = {
    [uid: string]: { name: string, trusted: boolean }
}
interface IState {
    user: {
        displayName: string,
        email: string,
        uid: string,
        type: "client" | "robot" | "unknown",
    },
    loggedIn: null | boolean,
    contacts: ContactsObject,
    contactStatus: { [uid: string]: boolean },
    connected: boolean,
    lastResetEmail: null | string,
    reverseSpinControl: boolean
}
interface ISelectorState {
    [key: string]: IState
}

const initialState: IState = {
    user: {
        displayName: "unknown",
        email: "unknown",
        uid: "unknown",
        type: "unknown",
    },
    loggedIn: null,
    contacts: {},
    contactStatus: {},
    connected: true,//false,
    lastResetEmail: null,
    reverseSpinControl: false
}

const firebaseSlice = createSlice({
    name: storeKey,
    initialState: initialState,
    reducers: {
        displayNameChanged(state, action) {
            state.user.displayName = action.payload
        },
        gotUserData(state, action) {
            state.contacts = action.payload.contacts || {}
            state.reverseSpinControl = action.payload.reverseSpinControl || false
        },
        setLastEmail(state, action) {
            state.lastResetEmail = action.payload || null
        },
        gotPublicUserData(state, action) {
            state.user.displayName = action.payload.displayName
            state.user.email = action.payload.email
        },
        userLoggedIn(state, action) {
            state.user.uid = action.payload.uid
            state.user.type = action.payload.type
            state.loggedIn = true
        },
        userLoggedOut(state) {
            state.user = initialState.user
            state.loggedIn = false
        },
        updateContactStatus(state, action) {
            state.contactStatus[action.payload.uid] = action.payload.status
        },
        setConnectionStatus(state, action) {
            state.connected = action.payload
        },
        setEmail(state, action) {
            state.user.email = action.payload
        }
    }
})

export const { actions, reducer } = firebaseSlice

storeInjectReducer(storeKey, reducer)

// --------------------------------- TOP LEVEL SELECTORS ----------------------------

const selectUser = (state: ISelectorState) => state[storeKey].user
const selectContacts = (state: ISelectorState) => state[storeKey].contacts
const selectContactStatus = (state: ISelectorState) => state[storeKey].contactStatus
const selectConnectedToDatabase = (state: ISelectorState) => state[storeKey].connected
const selectLastResetEmail = (state: ISelectorState) => state[storeKey].lastResetEmail
const selectLoggedIn = (state: ISelectorState) => state[storeKey].loggedIn
const selecReverseSpinControl = (state: ISelectorState) => state[storeKey].reverseSpinControl

// --------------------------- SELECTORS ---------------------------------- // 

export const getContactName = createSelector(
    getRemoteUID, selectContacts,
    (remoteUID, contacts) => {
        if (remoteUID && contacts) {
            return contacts[remoteUID].name
        } else {
            return "No Name"
        }
    }
)

export const getContactNameFromUID = (uid: string) => createSelector(
    selectContacts,
    (contacts) => {
        if (contacts[uid] && contacts[uid].name) {
            return contacts[uid].name
        } else {
            return "No Name"
        }
    }
)

const getReverseSpinControl = createSelector(selecReverseSpinControl, reverseSpinControl => reverseSpinControl)
export const useReverseSpinControl = () => useSelector(getReverseSpinControl)
const getDisplayName = createSelector(selectUser, user => user.displayName)
export const useDisplayName = () => useSelector(getDisplayName)
const getEmail = createSelector(selectUser, user => user.email)
export const useEmail = () => useSelector(getEmail)
const getUserLoggedIn = createSelector(selectLoggedIn, loggedIn => loggedIn)
export const useUserLoggedIn = () => useSelector(getUserLoggedIn)
export const getContacts = createSelector(selectContacts, contacts => contacts)
export const useContacts = () => useSelector(getContacts)
const getLocalUID = createSelector(selectUser, user => user.uid)
export const useLocalUID = () => useSelector(getLocalUID)
export const getContactsStatus = createSelector(selectContactStatus, contactsStatus => contactsStatus)
export const useContactStatus = () => useSelector(getContactsStatus)
export const getUserType = createSelector(selectUser, user => user.type)
export const useUserType = () => useSelector(getUserType)
const getConnectedToDatabase = createSelector(selectConnectedToDatabase, connected => connected)
export const useConnectedToDatabase = () => useSelector(getConnectedToDatabase)
const getLastResetEmail = createSelector(selectLastResetEmail, lastEmail => lastEmail)
export const useLastResetEmail = () => useSelector(getLastResetEmail)

export const useTrustedCaller = (contactID: string) => useSelector((state: ISelectorState) => {
    const contacts = selectContacts(state)
    const contact = contacts[contactID]
    if(contact && contact.trusted) return contact.trusted
    return false  
})

export const useName = createSelectorHook(getContactName)
//export const useLocalUID = createHook(storeKey, 'user', 'uid')

export const getIsConnectedToRightSpin = createSelector(getSpinID, getLocalUID, (spinID, uid) => {
    if (uid === "unknown") return true;
    if (!spinID) return true;
    if (uid && spinID) {
        return spinID === uid
    }
    return true
})

// ------------------------------ actions ------------------------------- //
type UserLoggedIn = {
    user: {
        uid: string,
        type: string
    },
    loggedIn: boolean
}
type GotPublicUserData = {
    user: {
        displayName: string,
        email: string
    }
}

type GotUserData = {
    contacts: ContactsObject
}

type UpdateContactStatus = {
    contactStatus: { [uid: string]: string },
}

export const setEmail = (email: string) => store.dispatch(actions.setEmail(email))
export const setLastEmail = (seconds: string) => store.dispatch(actions.setLastEmail(seconds))
export const logOut = () => fbLogOut()
export const editDisplayName = (displayName: string) => fbEditDisplayName(displayName)
export const displayNameChanged = (displayName: string) => store.dispatch(actions.displayNameChanged(displayName))
export const userLoggedIn = (user: UserLoggedIn) => {
    store.dispatch(actions.userLoggedIn(user))
}
export const userLoggedOut = () => store.dispatch(actions.userLoggedOut())
export const gotPublicUserData = (data: GotPublicUserData) => {
    store.dispatch(actions.gotPublicUserData(data))
}
export const gotUserData = (data: GotUserData) => {
    store.dispatch(actions.gotUserData(data))
}
export const updateContactStatus = (data: UpdateContactStatus) => { store.dispatch(actions.updateContactStatus((data))) }
export const setConnectionStatus = (status: boolean) => store.dispatch(actions.setConnectionStatus(status))


export const getContactUids = () => {
    return Object.keys(store.getState()[storeKey].contacts)
}
export const getUID = () => {
    return store.getState()[storeKey].user.uid
}

export function getTrustedCallerStatus(contactID: string) {
    const contacts = selectContacts(store.getState())
    const contact = contacts[contactID];
    if(contact && contact.trusted) return contact.trusted
    return false
}

export const reverseSpinControlSubscribe = createSubscriber(storeKey, "reverseSpinControl")